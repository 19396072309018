<template>
    <div class="ml-4 mb-4">
        <b-table striped :items="loginInfo" :busy="loading" class="mt-2">
             <template #cell(OS)="data">
                <font-awesome-icon :icon="data.value.icon" class="mr-2"></font-awesome-icon>
                <b>
                    {{ data.value.name }} {{ data.value.version }}
                </b>
            </template>
        </b-table>
        <div
            v-if="loading"
            class="text-center"
        >
            <b-spinner
                variant="primary"
            />
        </div>
        <div
            v-else
            class="text-center mt-2"
        >
            <b-modal
                :id="`modal-${id}`"
                title="User Activity"
                lazy
                scrollable
                size="xl"
                ok-only
                ok-title="Dismiss"
            >
                <template #default>
                    <login-info
                        class="mt-4"
                        :id="id"
                    />
                </template>
            </b-modal>
            <b-button
                v-if="loginInfo.length < maxRecords"
                v-b-modal="`modal-${id}`"
                class="sc-btn pill"
            >
                <font-awesome-icon icon="running" class="mr-2" />
                Full Activity Record
            </b-button>
            <h6 v-else-if="loginInfo.length >= maxRecords">You've reached the end of this user's activity record.</h6>
            <h6 v-else>No account activity to display.</h6>
        </div>
    </div>
</template>
<script>
import UAParser from 'ua-parser-js';

export default {
    name: "login-info",
    props: [
        "id",
        "max",
        "get-user"
    ],
    data() {
        return {
            loading: false,
            loginInfo: [],
            loginCount: null,
            maxRecords: 500
        };
    },
    mounted() {
        this.loading = true;
        this.$api.get("/api/admin/users/login-info", { params: { user_id: this.id, stop: this.max, getUser: this.getUser } }).then((response) => {
            this.loading = false;
            const data = response.data;
            this.loginInfo = data.loginInfo.map((timelog) => {
                var row = { OS: "", device: "", browser: "", time: "" };
                const ua = new UAParser(timelog.device_info);
                var result = ua.getResult();
                if (!result.os.name) {
                    row.OS = { name: "Unknown", icon: "question" };
                }
                else {
                    row.OS = result.os;
                    var name = row.OS.name;
                    
                    row.OS.icon = ["fab", name.toLowerCase()];
                }
                row.device = result.device.model || "------";
                row.browser = result.browser.name || "------";
                row.time = this.$moment(timelog.logged_time).format("LLLL");
                return row;
            });
            this.loginCount = data.loginCount;
            this.$emit("fetched", data);
            this.loading = false;
        });
    }
}
</script>